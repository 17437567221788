<script setup lang="ts">
import { darkTheme, dateDeDE, deDE } from "naive-ui"

import { H } from "highlight.run"
import { initialiseStores } from "~/utils/storeInitializer"
import { useTheme } from "~/composables/useTheme"
import { useSupabaseClient } from "#imports"
import { Database } from "~/composables/generated/supabaseTypes"

const useDark = useTheme()
const supabase = useSupabaseClient<Database>()

setTimeout(async () => {
    await initialiseStores()
}, 0)

supabase.auth.onAuthStateChange((event, session) => {
    if (event === "INITIAL_SESSION" || event === "USER_UPDATED" || event === "SIGNED_IN" || event === "SIGNED_OUT") {
        if (session?.user) {
            H.identify(session.user.id, {
                email: session.user.email ?? "UNKNOWN_EMAIL",
            })
        }
    }

    if (event === "SIGNED_IN") {
        setTimeout(async () => {
            await initialiseStores()
        }, 0)
    } else if (event === "SIGNED_OUT") {
        // This will clear the store, because the user won't have permissions.
        setTimeout(async () => {
            await initialiseStores()
        }, 0)
    }
})
</script>

<template>
    <div class="w-full h-full">
        <n-config-provider
            :theme="useDark === 'dark' ? darkTheme : null"
            class="w-full h-full"
            :locale="deDE"
            :date-locale="dateDeDE"
            :inline-theme-disabled="false"
        >
            <n-loading-bar-provider>
                <n-notification-provider>
                    <n-dialog-provider>
                        <n-message-provider>
                            <n-layout class="w-full h-full">
                                <div class="w-full h-full">
                                    <nuxt-layout />
                                </div>
                            </n-layout>
                        </n-message-provider>
                    </n-dialog-provider>
                </n-notification-provider>
            </n-loading-bar-provider>
        </n-config-provider>
    </div>
</template>

<style lang="scss">
* {
    padding: 0;
    margin: 0;
}

html,
body,
#__nuxt {
    width: 100%;
    height: 100%;
}

img,
video {
    max-width: 100%;
    max-height: 100%;
    height: auto;
}
</style>
