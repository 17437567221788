import { reactive } from "vue"
import { acceptHMRUpdate, defineStore } from "pinia"
import { Database } from "~/composables/generated/supabaseTypes"
import { registerRealtimeDatabaseHandler, useGlobalNaiveUi, useSupabaseClient } from "#imports"

export type Supplier = Database["public"]["Tables"]["suppliers"]["Row"]
export type SupplierUpdate = Database["public"]["Tables"]["suppliers"]["Update"]
export type SupplierInsert = Database["public"]["Tables"]["suppliers"]["Insert"]

export const useSupplierStore = defineStore("SupplierStore", {
    state: () => ({
        suppliers: reactive<Supplier[]>([]),
    }),
    actions: {
        async init() {
            const supabase = useSupabaseClient<Database>()
            registerRealtimeDatabaseHandler("public", "suppliers", this.suppliers, (supplier) => supplier.id)
            const data = await supabase.from("suppliers").select("*")
            this.suppliers.splice(0, this.suppliers.length, ...(data.data ?? []))
        },
        async addSupplier(name: string) {
            const supabase = useSupabaseClient<Database>()

            const res = await supabase.from("suppliers").insert({ name })

            if (res.error) {
                const notification = useGlobalNaiveUi()?.notification
                notification?.error({
                    title: "Fehler beim Hinzufügen des Lieferanten",
                    meta: "Code: " + res.error.code,
                    duration: 5000,
                })
                return false
            }
            return true
        },
        async updateSupplier(supplierId: string, supplier: SupplierUpdate) {
            const supabase = useSupabaseClient<Database>()

            const res = await supabase.from("suppliers").update(supplier).match({ id: supplierId })
            return res.error == null
        },
    },
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useSupplierStore, import.meta.hot))
}
