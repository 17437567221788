// noinspection JSUnusedGlobalSymbols

import { REALTIME_LISTEN_TYPES, RealtimeChannel } from "@supabase/realtime-js"
import { diffPatch } from "diff-em"
import { apply_patch as applyPatch } from "jsonpatch"
import { useSupabaseClient } from "#build/imports"
import { Database } from "~/composables/generated/supabaseTypes"

/**
 * Registers a realtime database handler for a table.
 * @param table Table to use
 * @param updateArray  The Array which should be kept up to date
 * @param getKey Function that gets a unique key from the array item
 * @param schema Schema to use
 * @param transform Optional function to transform the data before it is added to the array
 */
export function registerRealtimeDatabaseHandler<
    S extends string & keyof Database,
    T extends string & keyof Database[S]["Tables"],
    // @ts-ignore - This is a generic function, but TS doesn't like it
    R extends Database[S]["Tables"][T]["Row"],
    D,
>(schema: S, table: T, updateArray: D[], getKey: (x: D) => any, transform?: (x: R) => D): RealtimeChannel | null {
    if (process.server) {
        return null
    }

    // console.log("Registering realtime handler for table", table)

    const supabase = useSupabaseClient<Database>()
    return supabase
        .channel(`${table}`)
        .on(
            REALTIME_LISTEN_TYPES.POSTGRES_CHANGES,
            {
                event: "*",
                schema,
                table,
            },
            (payload) => {
                if (payload.eventType === "INSERT") {
                    if (transform != null) {
                        // @ts-ignore
                        payload.new = transform(payload.new as R) as D
                    }
                    updateArray.push(payload.new as D)
                } else if (payload.eventType === "UPDATE") {
                    if (transform != null) {
                        // @ts-ignore
                        payload.new = transform(payload.new as R) as D
                    }

                    const index = updateArray.findIndex((x) => getKey(x) === getKey(payload.new as D))
                    if (index === -1) {
                        return
                    }

                    const elementToUpdate = updateArray[index] as Object
                    const diff = diffPatch(elementToUpdate as Object, payload.new)
                    const patchedObject = applyPatch(elementToUpdate, diff)

                    for (const key in patchedObject) {
                        // @ts-ignore
                        elementToUpdate[key] = patchedObject[key]
                    }
                } else if (payload.eventType === "DELETE") {
                    if (transform != null) {
                        // @ts-ignore
                        payload.old = transform(payload.old as R) as D
                    }
                    const index = updateArray.findIndex((x) => getKey(x) === getKey(payload.old as D))
                    if (index !== -1) {
                        updateArray.splice(index, 1)
                    }
                }
            }
        )
        .subscribe()
}
