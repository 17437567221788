import { default as signup3OiMtaKLxGMeta } from "/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/auth/signup.vue?macro=true";
import { default as verifypCPflc0efVMeta } from "/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/auth/verify.vue?macro=true";
import { default as indexUF0vUchfw2Meta } from "/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/index.vue?macro=true";
import { default as indexTc2IIM11YZMeta } from "/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/inventory/index.vue?macro=true";
import { default as _91_91productId_93_93gXLfLwT5lmMeta } from "/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/manage/products/[[supplierId]]/[[productId]].vue?macro=true";
import { default as _91_91supplierId_93_93bGSVMADYaPMeta } from "/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/manage/supplier/[[supplierId]].vue?macro=true";
import { default as merkmale6RkgsmZjf8Meta } from "/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/prodigy/merkmale.vue?macro=true";
import { default as title0dv7tUa5pdMeta } from "/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/prodigy/title.vue?macro=true";
import { default as category7zD6ipaRYqMeta } from "/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/settings/category.vue?macro=true";
import { default as glossaries6Vsywr6k6KMeta } from "/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/settings/glossaries.vue?macro=true";
import { default as index0zz3prixfZMeta } from "/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/tasks/index.vue?macro=true";
import { default as compareCIFTPjnoFrMeta } from "/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/tools/compare.vue?macro=true";
import { default as image_download0OhgBPxyH2Meta } from "/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/tools/image_download.vue?macro=true";
import { default as pictures00iTpcrztdMeta } from "/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/tools/pictures.vue?macro=true";
import { default as translate3uHlCIcRasMeta } from "/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/tools/translate.vue?macro=true";
export default [
  {
    name: signup3OiMtaKLxGMeta?.name ?? "auth-signup",
    path: signup3OiMtaKLxGMeta?.path ?? "/auth/signup",
    meta: signup3OiMtaKLxGMeta || {},
    alias: signup3OiMtaKLxGMeta?.alias || [],
    redirect: signup3OiMtaKLxGMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: verifypCPflc0efVMeta?.name ?? "auth-verify",
    path: verifypCPflc0efVMeta?.path ?? "/auth/verify",
    meta: verifypCPflc0efVMeta || {},
    alias: verifypCPflc0efVMeta?.alias || [],
    redirect: verifypCPflc0efVMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/auth/verify.vue").then(m => m.default || m)
  },
  {
    name: indexUF0vUchfw2Meta?.name ?? "index",
    path: indexUF0vUchfw2Meta?.path ?? "/",
    meta: indexUF0vUchfw2Meta || {},
    alias: indexUF0vUchfw2Meta?.alias || [],
    redirect: indexUF0vUchfw2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexTc2IIM11YZMeta?.name ?? "inventory",
    path: indexTc2IIM11YZMeta?.path ?? "/inventory",
    meta: indexTc2IIM11YZMeta || {},
    alias: indexTc2IIM11YZMeta?.alias || [],
    redirect: indexTc2IIM11YZMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/inventory/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91productId_93_93gXLfLwT5lmMeta?.name ?? "manage-products-supplierId-productId",
    path: _91_91productId_93_93gXLfLwT5lmMeta?.path ?? "/manage/products/:supplierId?/:productId?",
    meta: _91_91productId_93_93gXLfLwT5lmMeta || {},
    alias: _91_91productId_93_93gXLfLwT5lmMeta?.alias || [],
    redirect: _91_91productId_93_93gXLfLwT5lmMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/manage/products/[[supplierId]]/[[productId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91supplierId_93_93bGSVMADYaPMeta?.name ?? "manage-supplier-supplierId",
    path: _91_91supplierId_93_93bGSVMADYaPMeta?.path ?? "/manage/supplier/:supplierId?",
    meta: _91_91supplierId_93_93bGSVMADYaPMeta || {},
    alias: _91_91supplierId_93_93bGSVMADYaPMeta?.alias || [],
    redirect: _91_91supplierId_93_93bGSVMADYaPMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/manage/supplier/[[supplierId]].vue").then(m => m.default || m)
  },
  {
    name: merkmale6RkgsmZjf8Meta?.name ?? "prodigy-merkmale",
    path: merkmale6RkgsmZjf8Meta?.path ?? "/prodigy/merkmale",
    meta: merkmale6RkgsmZjf8Meta || {},
    alias: merkmale6RkgsmZjf8Meta?.alias || [],
    redirect: merkmale6RkgsmZjf8Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/prodigy/merkmale.vue").then(m => m.default || m)
  },
  {
    name: title0dv7tUa5pdMeta?.name ?? "prodigy-title",
    path: title0dv7tUa5pdMeta?.path ?? "/prodigy/title",
    meta: title0dv7tUa5pdMeta || {},
    alias: title0dv7tUa5pdMeta?.alias || [],
    redirect: title0dv7tUa5pdMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/prodigy/title.vue").then(m => m.default || m)
  },
  {
    name: category7zD6ipaRYqMeta?.name ?? "settings-category",
    path: category7zD6ipaRYqMeta?.path ?? "/settings/category",
    meta: category7zD6ipaRYqMeta || {},
    alias: category7zD6ipaRYqMeta?.alias || [],
    redirect: category7zD6ipaRYqMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/settings/category.vue").then(m => m.default || m)
  },
  {
    name: glossaries6Vsywr6k6KMeta?.name ?? "settings-glossaries",
    path: glossaries6Vsywr6k6KMeta?.path ?? "/settings/glossaries",
    meta: glossaries6Vsywr6k6KMeta || {},
    alias: glossaries6Vsywr6k6KMeta?.alias || [],
    redirect: glossaries6Vsywr6k6KMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/settings/glossaries.vue").then(m => m.default || m)
  },
  {
    name: index0zz3prixfZMeta?.name ?? "tasks",
    path: index0zz3prixfZMeta?.path ?? "/tasks",
    meta: index0zz3prixfZMeta || {},
    alias: index0zz3prixfZMeta?.alias || [],
    redirect: index0zz3prixfZMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/tasks/index.vue").then(m => m.default || m)
  },
  {
    name: compareCIFTPjnoFrMeta?.name ?? "tools-compare",
    path: compareCIFTPjnoFrMeta?.path ?? "/tools/compare",
    meta: compareCIFTPjnoFrMeta || {},
    alias: compareCIFTPjnoFrMeta?.alias || [],
    redirect: compareCIFTPjnoFrMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/tools/compare.vue").then(m => m.default || m)
  },
  {
    name: image_download0OhgBPxyH2Meta?.name ?? "tools-image_download",
    path: image_download0OhgBPxyH2Meta?.path ?? "/tools/image_download",
    meta: image_download0OhgBPxyH2Meta || {},
    alias: image_download0OhgBPxyH2Meta?.alias || [],
    redirect: image_download0OhgBPxyH2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/tools/image_download.vue").then(m => m.default || m)
  },
  {
    name: pictures00iTpcrztdMeta?.name ?? "tools-pictures",
    path: pictures00iTpcrztdMeta?.path ?? "/tools/pictures",
    meta: pictures00iTpcrztdMeta || {},
    alias: pictures00iTpcrztdMeta?.alias || [],
    redirect: pictures00iTpcrztdMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/tools/pictures.vue").then(m => m.default || m)
  },
  {
    name: translate3uHlCIcRasMeta?.name ?? "tools-translate",
    path: translate3uHlCIcRasMeta?.path ?? "/tools/translate",
    meta: translate3uHlCIcRasMeta || {},
    alias: translate3uHlCIcRasMeta?.alias || [],
    redirect: translate3uHlCIcRasMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ProductManagement/ProductManagement/frontend/src/pages/tools/translate.vue").then(m => m.default || m)
  }
]