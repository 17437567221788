import revive_payload_client_4sVQNw7RlN from "/home/runner/work/ProductManagement/ProductManagement/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/ProductManagement/ProductManagement/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/ProductManagement/ProductManagement/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_I4Hv4qpJHd from "/home/runner/work/ProductManagement/ProductManagement/frontend/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_yVLowv6hDl from "/home/runner/work/ProductManagement/ProductManagement/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/ProductManagement/ProductManagement/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/ProductManagement/ProductManagement/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/ProductManagement/ProductManagement/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/ProductManagement/ProductManagement/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import auth_redirect_hxxEaFfrIx from "/home/runner/work/ProductManagement/ProductManagement/frontend/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.mjs";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/ProductManagement/ProductManagement/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import highlightio_client_IhN8Kl1oVh from "/home/runner/work/ProductManagement/ProductManagement/frontend/src/plugins/highlightio.client.ts";
import naiveUi_ttpDYBaLUh from "/home/runner/work/ProductManagement/ProductManagement/frontend/src/plugins/naiveUi.ts";
import vueErrorhandler_LUuaT69nto from "/home/runner/work/ProductManagement/ProductManagement/frontend/src/plugins/vueErrorhandler.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  supabase_client_I4Hv4qpJHd,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  auth_redirect_hxxEaFfrIx,
  chunk_reload_client_UciE0i6zes,
  highlightio_client_IhN8Kl1oVh,
  naiveUi_ttpDYBaLUh,
  vueErrorhandler_LUuaT69nto
]