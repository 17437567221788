import { H } from "highlight.run"
import { defineNuxtPlugin, useRuntimeConfig } from "#imports"

// noinspection JSUnusedGlobalSymbols
export default defineNuxtPlugin({
    name: "highlightio",
    hooks: {
        "app:created": () => {
            const runtimeConfig = useRuntimeConfig()
            H.init("jgovpz6d", {
                environment: process.env.NODE_ENV === "development" ? "development" : "production",
                version: runtimeConfig.public.VERSION ?? "UNKNOWN_VERSION",
                enableSegmentIntegration: true,
                enablePerformanceRecording: true,
                tracingOrigins:
                    process.env.NODE_ENV === "development"
                        ? ["localhost:4000", "localhost:54321"]
                        : ["api.manage.intern.sunga.de", "kong.sb.intern.sunga.de", "manage.intern.sunga.de"],
                networkRecording: {
                    enabled: true,
                    recordHeadersAndBody: true,
                    urlBlocklist: [
                        // insert full or partial urls that you don't want to record here
                        // Out of the box, Highlight will not record these URLs (they can be safely removed):
                        "https://www.googleapis.com/identitytoolkit",
                        "https://securetoken.googleapis.com",
                    ],
                },
            })
            console.log("Environment:", process.env.NODE_ENV === "development" ? "development" : "production")
        },
    },
})
