import { DialogApiInjection } from "naive-ui/es/dialog/src/DialogProvider"
import { LoadingBarInst } from "naive-ui/es/loading-bar/src/LoadingBarProvider"
import { MessageApiInjection } from "naive-ui/es/message/src/MessageProvider"
import { NotificationApiInjection } from "naive-ui/es/notification/src/NotificationProvider"
import { AxiosError } from "axios"
import { H } from "highlight.run"
import { defineNuxtPlugin } from "#imports"

// noinspection JSUnusedGlobalSymbols
export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.config.errorHandler = (err, _vm, _info) => {
        H.consumeError(err as Error)
        console.error(err)
        const vueGlobalSettings = nuxtApp.vueApp.config.globalProperties
        const notification = vueGlobalSettings.$naiveUi?.notification
        if (notification) {
            if (err instanceof AxiosError) {
                if (err.response) {
                    notification.error({
                        title: err.response.data.title ?? "Fehler",
                        content: err.response.data.message ?? err.response.statusText,
                        meta: () => err.response!.data.code ?? err.response!.status.toString(),
                    })
                }
            } else {
                notification.error({
                    title: "Fehler",
                    // @ts-ignore
                    content: err.message,
                })
            }
        }
    }
})

export interface NaiveUiInjections {
    message: MessageApiInjection | null
    dialog: DialogApiInjection | null
    loadingBar: LoadingBarInst | null
    notification: NotificationApiInjection | null
}

declare module "@vue/runtime-core" {
    // noinspection JSUnusedGlobalSymbols
    export interface ComponentCustomProperties {
        $naiveUi: NaiveUiInjections | undefined
    }
}
